import React from "react";
import "../styles/AboutUs.scss";
import { useTranslation } from "react-i18next";
import { WordFromOwner } from "../components/WordFromOwner";

export const AboutUs = () => {
    const { t } = useTranslation();
    return (
        <div className="PageContainer">
            <div className="AboutSection">
                <h2>{t("AboutUs")}</h2>
                <h4>{t("RealEstateConsultancy")}</h4>
                <p>{t("AboutUsContent")}</p>
                <p className="Quote">{t("AboutUsQuote")}</p>
                <img src="/images/AboutUs.jpeg" alt={t("ALTs.AboutUsLargeImage")} />
            </div>
            <h4> </h4>
            <WordFromOwner />
            <h1 className="TextColorWhite">.</h1>
        </div>);
}