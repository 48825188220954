import React from "react";
import { useTranslation } from 'react-i18next'
import "../styles/Footer.scss";

const SocialMediaIcon = ({ icon }) => {
    return (
        <img src={icon.img} alt={icon.alt} onClick={() => {
            //TODO: Do redirection to icon.link
        }} />
    );
};

export const Footer = ({ menuItems, selected }) => {
    const { t } = useTranslation();
    const socialMediaIcons = [
        {
            img: "/images/SocialIconInstagram.png",
            alt: "",
            link: ""
        },
        {
            img: "/images/SocialIconFacebook.png",
            alt: "",
            link: ""
        },
        {
            img: "/images/SocialIconX.png",
            alt: "",
            link: ""
        },
        {
            img: "/images/SocialIconLinkedin.png",
            alt: "",
            link: ""
        }
    ];

    return (
        <div className="FooterContainer">
            <img className="logo" src="/images/LogoV2.svg" alt="MRAY logo" />
            <div className="FooterMenuItems">
                {menuItems.map((menuItem, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="MenuItem" >
                                <a className={selected === menuItem.path ? " selectedMenuItem" : ""}
                                    href={menuItem.path}>{t(menuItem.name)}</a>
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
            <div className="SocialMediaIconsBar">
                {socialMediaIcons.map((icon, index) => {
                    return (
                        <SocialMediaIcon icon={icon} key={index} />
                    );
                })}
            </div>
            <div className="CopyrightBar">
                <h5>Copyright © MRAY Consulting 2024</h5>
                <a href="/">{t("TermsOfUse")}</a>
                <a href="/">{t("PrivacyPolicy")}</a>
            </div>
        </div>
    );
};


