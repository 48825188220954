import React from "react";
import "../styles/Services.scss";
import { useTranslation } from "react-i18next";
import { ServiceDisplayObject } from "../components/ServiceDisplayObject";

export const SourcingAndAcquisition = () => {
    const { t } = useTranslation();
    return (
        <div className="PageContainer">
            <div className="ServicesSection">
                <h2>{t("SourcingAndAcquisition")}</h2>
            </div>
            {
                Array.from({ length: 8 }).map((obj, index) => {
                    return (
                        <ServiceDisplayObject
                            key={index}
                            index={index + 1}
                            colored={index % 2 !== 0}
                            cardType="SourcingAndAcquisitionCards" />
                    )
                })
            }
            <h3 className="TextColorWhite">.</h3>
        </div>);
}