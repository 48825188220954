import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HomePage, AboutUs, Services, Projects, VilaOlive, ContactUs } from './pages/';
// import reportWebVitals from './reportWebVitals';
import { Header } from "./components/Header";
import { Footer } from './components/Footer';
import { SourcingAndAcquisition } from './pages/SourcingAndAcquisition';
import { RealEstateDevelopment } from './pages/RealEstateDevelopment';
import './i18n';
// import { CountDownScreen } from './components/CountDownScreen';

// TODO: Merge this two collections.
const menuItems = [
  {
    path: "/",
    name: "Home",
  },
  {
    path: "/about_us",
    name: "About",
  },
  {
    path: "/services",
    name: "Services",
  },
  {
    path: "/projects",
    name: "Projects",
  },
  {
    path: "/villa_olive",
    name: "VilaOlive",
  },
  {
    path: "/contact_us",
    name: "CONTACTUS",
  },
];

const router = createBrowserRouter([
  {
    path: '/',
    element:
      <>
        <Header menuItems={menuItems} selected="/" />
        <HomePage />
        <Footer menuItems={menuItems} selected="/" />
        {/* <CountDownScreen /> */}
      </>,
    // errorElement: <UnexistingErrorPage/> We can add error page for 404 and other errors.
  },
  {
    path: '/about_us',
    element:
      <>
        <Header menuItems={menuItems} selected="/about_us" />
        <AboutUs />
        <Footer menuItems={menuItems} selected="/about_us" />
      </>,
  },
  {
    path: '/services',
    element: <>
      <Header menuItems={menuItems} selected="/services" />
      <Services />
      <Footer menuItems={menuItems} selected="/services" />
    </>,
  },
  {
    path: '/services/sourcing_and_acquisition',
    element: <>
      <Header menuItems={menuItems} selected="/services" />
      <SourcingAndAcquisition />
      <Footer menuItems={menuItems} selected="/services" />
    </>,
  },
  {
    path: '/services/real_estate_development',
    element: <>
      <Header menuItems={menuItems} selected="/services" />
      <RealEstateDevelopment />
      <Footer menuItems={menuItems} selected="/services" />
    </>,
  },
  {
    path: '/projects',
    element: <>
      <Header menuItems={menuItems} selected="/projects" />
      <Projects />
      <Footer menuItems={menuItems} selected="/projects" />
    </>,
  },
  {
    path: '/villa_olive',
    element: <>
      <Header menuItems={menuItems} selected="/villa_olive" />
      <VilaOlive />
      <Footer menuItems={menuItems} selected="/villa_olive" />
    </>,
  },
  {
    path: '/contact_us',
    element: <>
      <Header menuItems={menuItems} selected="/contact_us" />
      <ContactUs />
      <Footer menuItems={menuItems} selected="/contact_us" />
    </>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
