import React, { useEffect, useRef, useState } from "react";
import i18n from '../i18n';
import Cookies from 'universal-cookie';
import "../styles/LanguageSelector.scss";
import { useOutsideClickCallback } from "./Hooks/useOutsideClick";

const LanguageSelector = () => {
    const dropdownRef = useRef(null);

    const cookies = new Cookies();
    const languageFromCookie = cookies.get('selectedLanguage');
    const [toggle, setToggle] = useState(false);

    const [selectedLanguage, setSelectedLanguage] = useState(languageFromCookie ? languageFromCookie : i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    useOutsideClickCallback(dropdownRef, () => { setToggle(false) });

    useEffect(() => {
        cookies.set('selectedLanguage', selectedLanguage);
        i18n.changeLanguage(selectedLanguage);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(selectedLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLanguage])

    const dropDownOptions = {
        "en": {
            value: "en",
            img: "/images/flags/en.png",
            longTitle: "English",
            shortTitle: "EN"
        },
        "ru": {
            value: "ru",
            img: "/images/flags/ru.png",
            longTitle: "Russian",
            shortTitle: "RU"
        },
        "sr": {
            value: "sr",
            img: "/images/flags/sr.png",
            longTitle: "Serbian",
            shortTitle: "SR"
        }
    };

    return (
        <div className="MyDropdown Invisible" ref={dropdownRef}>
            <div className="DropdownButton" onClick={() => setToggle(!toggle)}>
                <img src={dropDownOptions[selectedLanguage].img} alt={dropDownOptions[selectedLanguage].longTitle + " flag image."} />
                <label>{dropDownOptions[selectedLanguage].shortTitle}</label>
                <img src="/images/ArrowDown.svg" alt="Dropdown arrow down." />
            </div>
            {toggle ?
                <div className="DropdownSelector">
                    <h5 className="TextColorWhite">.</h5>
                    {Object.values(dropDownOptions).map((option, index) => {
                        return (
                            option.value === selectedLanguage ? null :
                                <div key={index} className="DropDownOption" onClick={() => { setSelectedLanguage(option.value); setToggle(!toggle) }}>
                                    <img src={option.img} alt={option.longTitle + " flag image."} />
                                    <label>{option.longTitle}</label>
                                </div>);
                    })}
                    <h5 className="TextColorWhite">.</h5>
                </div> : null}

        </div>
    );
};

export default LanguageSelector;