import React, { useState } from "react";
import "../styles/TwoTabsContainer.scss";
import { useTranslation } from "react-i18next";

export const TwoTabsContainer = ({ leftPart, rightPart }) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <div className="PageElement">
            <div className="TabsContainer">
                <button onClick={() => setSelectedTab(0)}>{t("CurrentProjects")}</button>
                <button onClick={() => setSelectedTab(1)}>{t("PastProjects")}</button>
            </div>
            <div className="UnderlineBar">
                <div className="Underline" style={{ transform: `translate3d(${selectedTab * 100}%, 0, 0)` }} />
            </div>
            <div className="TabWrapper">
                <div className="TabContent" style={{ transform: `translate3d(${(-1 + selectedTab) * 100}%, 0, 0)` }}>
                    <div className="LeftContent">
                        {rightPart}
                    </div>
                    <div className="RightContent">
                        {leftPart}
                    </div>

                </div>
            </div>
        </div>
    );
}