import React from "react";
import "../styles/OurServicesCards.scss";
import { useTranslation } from "react-i18next";

export const OurServicesCards = () => {
    const { t } = useTranslation();

    const cards = [...Array(6).keys()];
    return (
        <div className="OurServicesContainer">
            <h4>{t("MRAYServices")}</h4>
            <h1>{t("OurServices")}</h1>
            <p>{t("OurServicesDescription")}</p>
            <div className="CardsContainer">
                {cards.map((number, index) => {
                    return (
                        <SingleCard index={number + 1} key={index} />
                    )
                })}

            </div>
        </ div >
    );
}

const SingleCard = ({ index }) => {
    const { t } = useTranslation();
    return (
        <div className="SingleCard" >
            <div className="VisibleCard">
                <img src={"/images/CardImg" + index + ".svg"} alt={t("ServiceCards.ImageAlt." + index)} />
                <h2>{t("ServiceCards.Title." + index)}</h2>
                <h3>{t("ServiceCards.Info." + index)}</h3>
                <a href={"/services"}>{t("MORE")}</a >
            </div>
        </div>
    );
}