import React from "react";
import "../styles/Home.scss";
import { useTranslation } from "react-i18next";
import { TwoPartsContainer } from "../components/TwoPartsContainer";
import { WordFromOwner } from "../components/WordFromOwner";
import { MrayButton } from "../components/Button";
import { OurServicesCards } from "../components/OurServicesCards";
// import { ImageScrollbar } from "../components/ImageScrollbar";
import { ImageScrollbarV2 } from "../components/ImageScrollbarV2";
import { useNavigate } from "react-router-dom";
import LanguageSelector from "../components/LanguageSelector";

export const HomePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const images =
        [
            {
                img: "/images/VillaOlivePreview1.png",
                alt: "ALTs.VillaOlivePreview"
            },
            {
                img: "/images/VillaOlivePreview2.png",
                alt: "ALTs.VillaOlivePreview"
            },
            {
                img: "/images/VillaOlivePreview3.png",
                alt: "ALTs.VillaOlivePreview"
            },
            {
                img: "/images/VillaOlivePreview4.png",
                alt: "ALTs.VillaOlivePreview"
            },
        ];

    return (
        <div className="PageContainer">
            <div className="HomeTitleContainer">
                <img className="HomeTitleContainerImage" alt={t("ALTs.HomeHeaderImg")} src="/images/Home1.jpeg" />
                <p>{t("HomeTitleQuote")}</p>
                <LanguageSelector />
            </div>
            <TwoPartsContainer
                leftPart={
                    <div className="SigleContainerAbout">
                        <div className="TitleSectionAbout">
                            <h2>{t("AboutUs")}</h2>
                            <h3>{t("AboutUsInfo")}</h3>
                        </div>
                        <p>{t("AboutUsDescriptionMini")}</p>
                        <MrayButton onClick={() => navigate("/about_us")} text={t("LearnMore")} />
                    </div>}
                rightPart={
                    <div className="SigleContainerAbout">
                        <img className="HomeTitleContainerImage" alt={t("ALTs.AboutUsHomeImg")} src="/images/HomeAbout.jpeg" />
                    </div>}
            />
            <h4> </h4>
            <WordFromOwner />
            <OurServicesCards />
            <div className="VillaOliveContainer">
                <h2>{t("VilaOlive2")}</h2>
                <p>{t("VileOliveAbout")}</p>
                <MrayButton onClick={() => navigate("/villa_olive")} text={t("LearnMore")} />
                <ImageScrollbarV2 images={images} />
            </div>
        </div>);
}