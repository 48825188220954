import React from "react";
import "../styles/TwoPartsContainer.scss";

export const TwoPartsContainer = ({ leftPart, rightPart, coloredBackground = false }) => {
    return (
        <div className={"TwoPartsComponent" + (coloredBackground ? " LightWhiteBackgroundOnBigScreen" : "")}>
            <div className="LeftPart">
                {leftPart}
            </div>
            <div className="RightPart">
                {rightPart}
            </div>
        </div>);
}