import React from "react";
import "../styles/Services.scss";
import { useTranslation } from "react-i18next";
import { ServiceDisplayObject } from "../components/ServiceDisplayObject";

export const Services = () => {
    const { t } = useTranslation();
    const servicesContent = [...Array(6).keys()];
    return (
        <div className="PageContainer">
            <div className="ServicesSection">
                <h2>{t("ServicesLowercase")}</h2>
                <p>{t("ServicesInfo")}</p>
            </div>
            {
                servicesContent.map((number, index) => {
                    return (
                        <ServiceDisplayObject
                            key={index}
                            index={number + 1}
                            colored={number % 2 !== 0}
                            cardType="ServiceCards" />
                    )
                })
            }
            <h3 className="TextColorWhite">.</h3>
        </div>);
}