import React, { useCallback, useEffect, useRef, useState } from "react";
import "../styles/ImageScrollbar.scss";
import { useWindowWidth } from "./Hooks/useWindowWidth";
import { useTranslation } from "react-i18next";

export const ImageScrollbarV2 = ({ images }) => {
    const { t } = useTranslation();
    const width = useWindowWidth();
    const element = document.getElementById('ImageScrollbarContainer');
    const imageWidth = 340 + 12; // + margins

    const [offset, setOffset] = useState(0);
    const [maxOffset, setMaxOffset] = useState(0)
    const delay = 5000;
    const timeoutRef = useRef(null);

    const calculateMaxOffset = useCallback((element) => {
        if (!element) {
            return;
        }

        return element.offsetWidth - (imageWidth * images.length);
    }, [imageWidth, images.length]);

    useEffect(() => {
        setMaxOffset(calculateMaxOffset(element))
    }, [calculateMaxOffset, element, width]);

    const moveForward = () => {
        if (offset >= maxOffset) {
            let tempOffset = offset - imageWidth;
            if (tempOffset < maxOffset)
                tempOffset = maxOffset;
            setOffset(tempOffset);
        }
    };

    const moveBack = () => {
        if (offset < 0) {
            let tempOffset = offset + imageWidth;
            if (tempOffset > 0)
                tempOffset = 0;
            setOffset(tempOffset);
        }
    };

    // Auto scroll
    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }
    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () => offset === maxOffset ? setOffset(0) : moveForward(),
            delay
        );
        return () => {
            resetTimeout();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images.length, maxOffset, offset]);

    return (
        <>
            <div id="ImageScrollbarContainer" className="ImageScrollbarContainer" style={{ transform: `translate3d(${offset}px, 0, 0)` }}>
                {images.map((image, index) => {
                    return (
                        <div className="ImageContainer" key={index} >
                            <img className="ImageScrollbarContainer" alt={t(image.alt)} src={image.img} />
                        </div>);
                })}
            </div>
            <div className="PagingContainer">
                <button onClick={moveBack} className={offset === 0 ? "Dissabled" : ""}>
                    <img src="/images/ArrowLeft.svg" alt="Left slider arrow" />
                </button>
                <button onClick={moveForward}
                    className={offset <= maxOffset ? "Dissabled" : ""}>
                    <img src="/images/ArrowRight.svg" alt="Right slider arrow" />
                </button>
            </div>
        </>

    );
}