import React, { useCallback } from "react";
import "../styles/ServiceDisplayObject.scss";
import { useTranslation } from "react-i18next";
import { TwoPartsContainer } from "../components/TwoPartsContainer";

export const ServiceDisplayObject = ({ index, colored, cardType }) => {
    const { t } = useTranslation();
    const tempContent = t(cardType + ".Content." + index);
    const contentCollection = tempContent ? tempContent.split("|") : [];
    const imagesFolder = useCallback((cardType) => {
        switch (cardType) {
            case "ServiceCards":
                return "services";
            case "SourcingAndAcquisitionCards":
                return "sourcing_and_acquisition";
            case "RealEstateDevelopmentCards":
                return "real_estate_development";
            default:
                return "";
        }
    }, []);
    return (
        <TwoPartsContainer
            coloredBackground={colored}
            leftPart={
                <div className="ServiceSection LeftSection">
                    {/* And what if they add more services and it goes over 10? */}
                    <h2>{"0" + index}</h2>
                    <h3 className="OnlyAtSmallScreen">{t(cardType + ".Title." + index)}</h3>
                    <img src={("/images/" + imagesFolder(cardType) + "/" + index + ".jpeg")} alt={t(cardType + ".ImageAlt." + index)} />
                </div>}
            rightPart={
                <div className="ServiceSection RightSection">
                    <h3 className="OnlyAtBigScreen">{t(cardType + ".Title." + index)}</h3>
                    {contentCollection.map((paragraph, index) => { return (<p key={index} >{paragraph}</p>) })}
                    {(t(cardType + ".Link." + index)) ? <a href={t(cardType + ".Link." + index)}>{t("FINDOUTMORE")}</a> : null}
                </div>} />
    );
}