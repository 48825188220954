import React from "react";
import "../styles/Projects.scss";
import { useTranslation } from "react-i18next";
import { TwoTabsContainer } from "../components/TwoTabsContainer";
import { ProjectContainer } from "../components/ProjectContainer";

export const Projects = () => {
    const { t } = useTranslation();

    return (
        <div className="PageContainer">
            <div className="TitleSection">
                <h2>{t("OurProjects")}</h2>
                <p>"{t("OurProjectsInfo")}"</p>
            </div>
            <TwoTabsContainer
                leftPart={<CurrentProjects />}
                rightPart={<PastProjects />} />
        </div>);
}

const CurrentProjects = () => {
    const { t } = useTranslation();
    const projects = [
        {
            images: [
                { src: "/images/villa_olive/5.jpg", alt: t("ProjectsContent.Current.ImagesAlt.1.1") },
                { src: "/images/villa_olive/6.jpg", alt: t("ProjectsContent.Current.ImagesAlt.1.2") },
                { src: "/images/villa_olive/9.jpg", alt: t("ProjectsContent.Current.ImagesAlt.1.3") },
                { src: "/images/villa_olive/10.jpg", alt: t("ProjectsContent.Current.ImagesAlt.1.4") },
                { src: "/images/villa_olive/11.jpg", alt: t("ProjectsContent.Current.ImagesAlt.1.5") },
                { src: "/images/villa_olive/13.jpg", alt: t("ProjectsContent.Current.ImagesAlt.1.6") },
                { src: "/images/villa_olive/14.jpg", alt: t("ProjectsContent.Current.ImagesAlt.1.7") },
                { src: "/images/villa_olive/8.jpg", alt: t("ProjectsContent.Current.ImagesAlt.1.8") },
                { src: "/images/villa_olive/15.JPG", alt: t("ProjectsContent.Current.ImagesAlt.1.9") },
                { src: "/images/villa_olive/16.png", alt: t("ProjectsContent.Current.ImagesAlt.1.10") },
                { src: "/images/villa_olive/17.jpg", alt: t("ProjectsContent.Current.ImagesAlt.1.11") }
            ],
            splitList: true,
        },
        {
            images: [
                { src: "/images/smokva_bay/1.png", alt: t("ProjectsContent.Current.ImagesAlt.2.1") },
                { src: "/images/smokva_bay/3.png", alt: t("ProjectsContent.Current.ImagesAlt.2.2") },
                { src: "/images/smokva_bay/4.png", alt: t("ProjectsContent.Current.ImagesAlt.2.3") },
                { src: "/images/smokva_bay/5.png", alt: t("ProjectsContent.Current.ImagesAlt.2.4") },
                { src: "/images/smokva_bay/6.png", alt: t("ProjectsContent.Current.ImagesAlt.2.5") },
            ],
            splitList: false,
        },
        {
            images: [
                { src: "/images/lastva_park/1.png", alt: t("ProjectsContent.Current.ImagesAlt.3.1") },
                { src: "/images/lastva_park/4.png", alt: t("ProjectsContent.Current.ImagesAlt.3.2") },
                { src: "/images/lastva_park/2.png", alt: t("ProjectsContent.Current.ImagesAlt.3.3") },
                { src: "/images/lastva_park/3.png", alt: t("ProjectsContent.Current.ImagesAlt.3.4") }
                // { src: "/images/lastva_park/5.png", alt: t("ProjectsContent.Current.ImagesAlt.3") },
                // { src: "/images/lastva_park/6.png", alt: t("ProjectsContent.Current.ImagesAlt.3") }
            ],
            splitList: false,
        },
        {
            images: [
                { src: "/images/zukovica_land/1.png", alt: t("ProjectsContent.Current.ImagesAlt.4.1") },
                { src: "/images/zukovica_land/2.png", alt: t("ProjectsContent.Current.ImagesAlt.4.2") },
                { src: "/images/zukovica_land/3.png", alt: t("ProjectsContent.Current.ImagesAlt.4.3") },
                { src: "/images/zukovica_land/4.png", alt: t("ProjectsContent.Current.ImagesAlt.4.4") }
            ],
            splitList: false,
        },
        {
            images: [
                { src: "/images/villa_toscana/10.png", alt: t("ProjectsContent.Current.ImagesAlt.5.1") },
                { src: "/images/villa_toscana/3.png", alt: t("ProjectsContent.Current.ImagesAlt.5.2") },
                { src: "/images/villa_toscana/6.png", alt: t("ProjectsContent.Current.ImagesAlt.5.3") },
                { src: "/images/villa_toscana/7.png", alt: t("ProjectsContent.Current.ImagesAlt.5.4") },
                { src: "/images/villa_toscana/8.png", alt: t("ProjectsContent.Current.ImagesAlt.5.6") },
                { src: "/images/villa_toscana/9.png", alt: t("ProjectsContent.Current.ImagesAlt.5.6") }
            ],
            splitList: false,
        },
        {
            images: [
                { src: "/images/stone_resort/1.png", alt: t("ProjectsContent.Current.ImagesAlt.6.1") },
                { src: "/images/stone_resort/4.jpeg", alt: t("ProjectsContent.Current.ImagesAlt.6.2") },
                { src: "/images/stone_resort/2.jpg", alt: t("ProjectsContent.Current.ImagesAlt.6.3") }
            ],
            splitList: false,
        },
        {
            images: [
                { src: "/images/villa_wave/2.png", alt: t("ProjectsContent.Current.ImagesAlt.7.1") },
                { src: "/images/villa_wave/4.png", alt: t("ProjectsContent.Current.ImagesAlt.7.2") },
                { src: "/images/villa_wave/1.JPG", alt: t("ProjectsContent.Current.ImagesAlt.7.3") },
                { src: "/images/villa_wave/5.png", alt: t("ProjectsContent.Current.ImagesAlt.7.4") }
            ],
            splitList: false,
        },
    ];
    return (<>
        {projects.map((project, index) => {
            return (<ProjectContainer
                key={index}
                index={index + 1}
                images={project.images}
                colored={index % 2 !== 0}
                splitList={project.splitList}
                projectType="Current" />);
        })}
    </>
    );

};

const PastProjects = () => {
    const { t } = useTranslation();
    const projects = [
        {
            forSale: true,
            images: [
                { src: "/images/eva_residences/1.png", alt: t("ProjectsContent.PastProjects.ImagesAlt.1.1") }
            ],
            splitList: true,
        },
        {
            forSale: true,
            images: [
                { src: "/images/lastva_park/7.png", alt: t("ProjectsContent.PastProjects.ImagesAlt.2.1") },
            ],
            splitList: true,
        },
        {
            forSale: true,
            images: [
                { src: "/images/lotus/1.png", alt: t("ProjectsContent.PastProjects.ImagesAlt.3.1") },
            ],
            splitList: true,
        },
        {
            forSale: true,
            images: [
                { src: "/images/ora_residences/1.png", alt: t("ProjectsContent.PastProjects.ImagesAlt.4.1") }
            ],
            splitList: true,
        },
        {
            forSale: true,
            images: [
                { src: "/images/secret_garden/1.png", alt: t("ProjectsContent.PastProjects.ImagesAlt.5.1") }
            ],
            splitList: true,
        },
        {
            forSale: true,
            images: [
                { src: "/images/stone_house/1.png", alt: t("ProjectsContent.PastProjects.ImagesAlt.6.1") },
            ],
            splitList: true,
        },
        {
            forSale: true,
            images: [
                { src: "/images/villa_olive/1.png", alt: t("ProjectsContent.PastProjects.ImagesAlt.7.1") },
            ],
            splitList: true,
        },
        {
            forSale: true,
            images: [
                { src: "/images/stone_house/2.png", alt: t("ProjectsContent.PastProjects.ImagesAlt.8.1") },
            ],
            splitList: true,
        },
    ];
    return (<>
        {projects.map((project, index) => {
            return (<ProjectContainer
                key={index}
                index={index + 1}
                images={project.images}
                colored={index % 2 !== 0}
                splitList={project.splitList}
                projectType="PastProjects" />);
        })}
    </>
    );
};
