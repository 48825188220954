import React, { useCallback, useEffect, useRef, useState } from "react";
import "../styles/ProjectContainer.scss";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "./Hooks/useWindowWidth";

export const ProjectContainer = ({ index, images, colored, splitList, projectType }) => {
    const { t } = useTranslation();
    let tempString = t("ProjectsContent." + projectType + ".BeforeListParagraphs." + index);
    let beforeListParagraphs = [];
    if (tempString) {
        beforeListParagraphs = tempString.split("|");
    }
    tempString = t("ProjectsContent." + projectType + ".ListContent." + index);
    let listContent = [];
    if (tempString) {
        listContent = tempString.split("|");
    }
    tempString = t("ProjectsContent." + projectType + ".AfterListParagraphs." + index);
    let afterListPAragraphs = [];
    if (tempString) {
        afterListPAragraphs = tempString.split("|");
    }
    tempString = t("ProjectsContent." + projectType + ".ButtomContent." + index);
    let buttomContent = [];
    if (tempString) {
        buttomContent = tempString.split("|");
    }

    return (
        <div className={("SingleProjectContainer" + (colored ? " LightWhiteBackground" : " ProjectNameDivAddon"))}>
            <div className="TopContainerPart">
                <div className="ProjectInfoSection">
                    <div className={("EnumerationDiv" + (projectType === "PastProjects" ? " ProjectNameDivAddon" : " ForSaleDivAddon"))}>
                        <h5>{index}.</h5>
                        {projectType === "PastProjects" ? <h4>{t("PROJECTNAME")}</h4> : <h4>{t("FORSALE")}</h4>}
                    </div>
                    <h2>{t("ProjectsContent." + projectType + ".Titles." + index)}</h2>
                    {beforeListParagraphs.map((para, index) => {
                        return (<p key={index}>{para}</p>);
                    })}
                    <ul className={("" + (splitList ? " ulSplit" : ""))}>
                        {listContent.map((li, index) => {
                            return (<li key={index}>{li}</li>);
                        })}
                    </ul>
                    {afterListPAragraphs.map((para, index) => {
                        return (<p key={index}>{para}</p>);
                    })}
                </div>
                <div className="ProjectImageSection">
                    <ImageSlider counter={index} images={images} />
                </div>


            </div>
            <div className="BottomContainerPart">
                {buttomContent.map((para, index) => {
                    return (<p key={index}>{para}</p>);
                })}
            </div>
        </div>);
}

const ImageSlider = ({ counter, images }) => {
    const element = document.getElementById('ImageSlider' + counter);
    const width = useWindowWidth();

    const [offset, setOffset] = useState(0);
    const [maxOffset, setMaxOffset] = useState(0)

    const delay = 5000;
    const timeoutRef = useRef(null);

    const calculateMaxOffset = useCallback((element) => {
        if (!element) {
            return;
        }

        return element.offsetWidth - (element.offsetWidth * images.length);
    }, [images.length]);

    useEffect(() => {
        setMaxOffset(calculateMaxOffset(element))
    }, [calculateMaxOffset, element, width]);

    const moveForward = () => {
        if (offset >= maxOffset) {
            let tempOffset = offset - element.offsetWidth;
            if (tempOffset < maxOffset)
                tempOffset = maxOffset;
            setOffset(tempOffset);
        }
    };

    const moveBack = () => {
        if (offset < 0) {
            let tempOffset = offset + element.offsetWidth;
            if (tempOffset > 0)
                tempOffset = 0;
            setOffset(tempOffset);
        }
    };

    // Auto scroll
    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }
    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () => offset === maxOffset ? setOffset(0) : moveForward(),
            delay
        );
        return () => {
            resetTimeout();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images.length, maxOffset, offset]);

    return (
        <div id={"ImageSlider" + counter} className="ImageSliderContainer">
            <div className="ImageSlider" style={{ transform: `translate3d(${offset}px, 0, 0)` }}>
                {images.map((image, index) => {
                    return (
                        <img key={index} src={image.src} alt={image.alt} />
                    );
                })}
            </div>
            {(images && images.length > 1) ? <div className="SliderButtonContainer">
                <button onClick={moveBack} className={(offset === 0 ? "buttonTransparent" : "")}>
                    <img src="/images/ArrowLeft.svg" alt="Left slider arrow" />
                </button>
                <button onClick={moveForward} className={("buttonGoRight" + (offset === maxOffset ? " buttonTransparent" : ""))}>
                    <img src="/images/ArrowRight.svg" alt="Right slider arrow" />
                </button>
            </div> : null}

        </div>

    );
};