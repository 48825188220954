import React, { useEffect, useRef, useState } from "react";
import "../styles/ContactUs.scss";
import { TwoPartsContainer } from "../components/TwoPartsContainer";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

export const ContactUs = () => {
    const { t } = useTranslation();

    useEffect(() => emailjs.init("O1uW1iAO1gw94vpJe"), []); // Maybe we could spice it so string won't be shown.

    const emailRef = useRef(null);
    const subjectRef = useRef(null);
    const messageRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [emailIsValid, setEmailIsValid] = useState(true);
    const [subjectIsValid, setSubjectIsValid] = useState(true);
    const [messageIsValid, setMessageIsValid] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const serviceId = "service_gnwlsl8";
        const templateId = "template_lt76rgm";


        if (!doValidation()) {
            return;
        }

        try {
            setLoading(true);
            await emailjs.send(serviceId, templateId, {
                senderEmail: emailRef.current.value,
                subject: subjectRef.current.value,
                senderMessage: messageRef.current.value
            });
            // alert("email successfully sent check inbox");
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            emailRef.current.value = "";
            subjectRef.current.value = "";
            messageRef.current.value = "";
            setEmailIsValid(true);
            setSubjectIsValid(true)
            setMessageIsValid(true);
        }
    };

    const doValidation = () => {
        let imputIsValid = true;

        if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(emailRef.current.value))) {
            imputIsValid = false;
            setEmailIsValid(false);
        }
        else {
            setEmailIsValid(true);
        }

        if (!subjectRef.current.value) {
            imputIsValid = false;
            setSubjectIsValid(false);
        }
        else {
            setSubjectIsValid(true);
        }

        if (!messageRef.current.value) {
            imputIsValid = false;
            setMessageIsValid(false);
        }
        else {
            setMessageIsValid(true);
        }

        return imputIsValid;
    }

    return (
        <div className="PageContainer ExpandToWholePage">
            <div className="TitleSection">
                <h2>{t("ContactUs")}</h2>
                <h3>{t("GetInTouch")}</h3>
            </div>

            <TwoPartsContainer
                leftPart={
                    <div className="BaseContainer">
                        <div className="FormContainer">
                            <div className="EmailContainer ">
                                <div className="KeySide">
                                    <h4>{t("Office")}</h4>
                                    <h4>{t("Contact")}</h4>
                                </div>
                                <div>
                                    <h4>{t("OfficeAddress")}</h4>
                                    <h4>{t("ContectEmails.1")}</h4>
                                    <h4>{t("ContectEmails.2")}</h4>
                                </div>
                            </div>
                            <div className="HorizontalLine" />
                            <h4>{t("SendUsAMessage")}</h4>
                            {/* <h3 className="TextColorWhite">{t("SendUsAMessage")}</h3> */}
                            <form onSubmit={handleSubmit}>
                                <input className={(emailIsValid ? "" : "InvalidInput")}
                                    ref={emailRef} placeholder={t("EMAIL")}>
                                </input>
                                <input className={(subjectIsValid ? "" : "InvalidInput")}
                                    ref={subjectRef} placeholder={t("SUBJECT")}>
                                </input>
                                <textarea className={(messageIsValid ? "" : "InvalidInput")}
                                    ref={messageRef} placeholder={t("MESSAGE")}  >
                                </textarea>
                                <button className={(loading ? "BtnDissabled" : "")} disabled={loading}>
                                    {t("SEND")}
                                </button>
                            </form>
                        </div>
                    </div>}
                rightPart={
                    <div className="RightSideImageContainer">
                        <img src="/images/office.jpeg" alt={t("ALTs.ContactUsImage")} />
                    </div>}
            />
            <h1 className="TextColorWhite">.</h1>
        </div>);
}