import React, { useState } from "react";
import { useTranslation } from 'react-i18next'
import "../styles/Header.scss";

export const Header = ({ menuItems, selected }) => {
  const { t } = useTranslation();
  const [toggleOn, setToggleOn] = useState(false);

  return (
    <>
      <div className="HeaderContainer" id="HeaderBody">
        <div className="HorizontalMenuItems">
          {menuItems.map((menuItem, index) => {
            return (
              < React.Fragment key={index}>
                <div className="HorizontalMenuItem" >
                  <a className={selected === menuItem.path ? " selectedMenuItem" : ""} href={menuItem.path}>{t(menuItem.name)}</a>
                </div>
                {index === 2 ? <img className="logo" src="/images/LogoV2.svg" alt="MRAY logo" /> : null}
              </React.Fragment>
            );
          })}
        </div>
        <div className="SideButtonContainer">
          <button className="toggleButton" onClick={() => { setToggleOn(!toggleOn); }}>
            <img className="toggleButtonImg" src="/images/Vector.svg" alt="Toggle button icon" />
          </button>
        </div>

      </div>
      {toggleOn ?
        <div className="HeaderContainerDropdown">
          <h2 className="MenuTitle">{t("Menu")}</h2>
          <div className="MenuItems">
            {menuItems.map((menuItem, index) => {
              return (
                <a key={index} className={selected === menuItem.path ? "selectedMenuItem" : ""} href={menuItem.path}>{t(menuItem.name)}</a>);
            })}
            <h4 className="TextColorWhite">.</h4>
          </div>
        </div> : null}
    </>
  );
}
