import React from "react";
import "../styles/WordFromOwner.scss";
import { TwoPartsContainerReverse } from "./TwoPartsContainerReverse";
import { useTranslation } from "react-i18next";

export const WordFromOwner = () => {
    const { t } = useTranslation();
    const wordFromOwner = t("WordFromOwner");
    let wordFromOwnerArray = [];
    if (wordFromOwner)
        wordFromOwnerArray = wordFromOwner.split("|");

    return (
        <TwoPartsContainerReverse
            leftPart={
                <div className="SigleContainerOwner">
                    <div className="TitleSection OnlyAtSmallScreen">
                        <h4>{t("WelcomeToMRAY")}</h4>
                        <h2>{t("WordFromOwnerTitle")}</h2>
                        <h1> </h1>
                    </div>
                    <div className="TitleSection OnlyAtBigScreen">
                        <h1 className="TextColorLightWhite">  .</h1>
                    </div>
                    {wordFromOwnerArray.map((paragraph, index) => { return (<p key={index}>{paragraph}</p>) })}
                    <p></p>
                    <h5 className="OnlyAtBigScreen">{t("FounderAndCEO")}</h5>
                    {/* <h6 className="OnlyAtBigScreen">{t("FounderName")}</h6> */}
                    <img className="Signature OnlyAtBigScreen" src="/images/signature.png" alt={t("OwnersSignature")} />
                </div>}
            rightPart={
                <div className="SigleContainerOwner">
                    <div className="TitleSection OnlyAtBigScreen">
                        <h1 className="TextColorLightWhite">.</h1>
                        <h4 >{t("WelcomeToMRAY")}</h4>
                        <h3 >{t("WordFromOwnerTitle")}</h3>
                    </div>
                    <h2> </h2>
                    <img className="OwnerImg HomeTitleContainerImage" alt={t("ALTs.OwnerImage")} src="/images/Owner.png" />
                    <h1 className="TextColorLightWhite OnlyAtBigScreen">  .</h1>
                    <h5 className="OnlyAtSmallScreen">{t("FounderAndCEO")}</h5>
                    <img className="Signature OnlyAtSmallScreen" src="/images/signature.png" alt={t("OwnersSignature")} />
                    {/* <h6 className="OnlyAtSmallScreen">{t("FounderName")}</h6> */}
                </div>}
        />);
}