import "../styles/TwoPartsContainerReverse.scss";

export const TwoPartsContainerReverse = ({ leftPart, rightPart, colored = true }) => {
    return (
        <div className={("TwoPartsComponentReverse" + (colored ? " AddColor" : ""))}>
            <div className="LeftPart">
                {leftPart}
            </div>
            <div className="RightPart">
                {rightPart}
            </div>
        </div>);
}