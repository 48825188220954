import React from "react";
import "../styles/VillaOlive.scss";
import { useTranslation } from "react-i18next";
// import { FourImageBar } from "../components/FourImageBar";
import { TwoPartsContainer } from "../components/TwoPartsContainer";
import { TwoPartsContainerReverse } from "../components/TwoPartsContainerReverse";

export const VilaOlive = () => {
    const { t } = useTranslation();
    const aboutVilaItemsTemp = t("AboutVilaItems.1");
    const aboutVilaItems = aboutVilaItemsTemp.split("|");

    return (
        <div className="PageContainer">
            <div className="TitleSection">
                <h2>{t("VilaOlive2")}</h2>
                <h4>"{t("VileOliveAbout")}"</h4>
            </div>
            <div className="PageElement ColoredPSection">
                <img src="/images//villa_olive/5.jpg" alt={t("ProjectsContent.Current.ImagesAlt.1.1")} />
            </div>
            {/* <FourImageBar
                images={[
                    { src: "/images//villa_olive/3.png", alt: "ALTs.VillaOliveFirst" },
                    { src: "/images//villa_olive/2.jpeg", alt: "ALTs.VillaOliveSecondUp" },
                    { src: "/images//villa_olive/1.jpeg", alt: "ALTs.VillaOliveSecondDown" },
                    { src: "/images//villa_olive/3.png", alt: "ALTs.VillaOliveThird" },
                ]} /> */}

            <div className="PageElement ColoredPSection">
                <p>{t("VillaOliveDescription.1")}</p>
                <p className="SecondP">{t("VillaOliveDescription.2")}</p>

            </div>
            <div className="PageElement ColoredPSection">
                <img src="/images//villa_olive/6.jpg" alt={t("ProjectsContent.Current.ImagesAlt.1.2")} />
            </div>
            <div className="PageElement">
                <TwoPartsContainerReverse colored={false}
                    rightPart={
                        <>
                            <h6 className="OnlyAtSmallScreen"> </h6>
                            <img className="ImagePart" src="/images//villa_olive/7.jpg" alt={t("ProjectsContent.Current.ImagesAlt.1.2")} />
                        </>}
                    leftPart={
                        <div className="TextPart MarginLeft">
                            <h2 className="">{t("AboutVilla")}</h2>
                            <ul>
                                {(Array.isArray(aboutVilaItems) && aboutVilaItems.length > 0) ?
                                    aboutVilaItems.map((item, index) => {
                                        return (<li key={index}>{item}</li>);
                                    }) : null}
                            </ul>
                            <p>{t("AboutVilaItems.2")}</p>
                            <p>{t("AboutVilaItems.3")}</p>
                            <p>{t("AboutVilaItems.4")}</p>
                        </div>} />
                <h4 className="TextColorWhite OnlyAtBigScreen">.</h4>
                <TwoPartsContainer
                    rightPart={
                        <>
                            <h6 className="OnlyAtSmallScreen"> </h6>
                            <img className="ImagePart" src="/images//villa_olive/8.jpg" alt={t("ProjectsContent.Current.ImagesAlt.1.8")} />
                        </>}
                    leftPart={
                        <div className="TextPart MarginRight">
                            <h3 className="AlignCenterOnSmall">{t("Interior")}</h3>
                            <h4 className="AlignCenterOnSmall">{t("Amenities")}</h4>
                            <p className="AlignCenterOnSmall">{t("AmenitiesItems")}</p>
                            <h3 className="AlignCenterOnSmall">{t("AdditionalFeatures")}</h3>
                            <p className="AlignCenterOnSmall">{t("AdditionalFeaturesItems")}</p>
                        </div>} />

            </div>
            <h1 className="TextColorWhite">.</h1>

        </div>);
}